import React from 'react';

const BottomMenu = () => {
  const menuItems = [
    { name: 'Магазин', icon: 'https://i.1.creatium.io/disk2/9f/78/1f/d3ae39fef22a6f37d8f2223f07ee0b6535/group_6.png' },
    { name: 'Приборы', icon: 'https://i.1.creatium.io/disk2/3d/4d/e4/891928fc1b76bb94be5966b3666718ef89/image_31.png' },
    { name: 'Меню', icon: 'https://i.1.creatium.io/disk2/06/6c/87/40b04d8692b4c2569364ab772867fd673a/image_34.png' },
    { name: 'Локации', icon: 'https://i.1.creatium.io/disk2/72/3f/71/5d99b6b0c477d9760746c6a8438141e55e/image_33.png' },
    { name: 'Садок', icon: 'https://i.1.creatium.io/disk2/9f/d2/4f/c7cc179ef2f6939f7e8dfe1127cdfedf91/group_5.png' },
  ];

  return (
    <div style={styles.menuContainer}>
      {menuItems.map((item, index) => (
        <div key={index} style={styles.menuItem}>
          <img src={item.icon} alt={item.name} style={styles.icon} />
          <span style={styles.text}>{item.name}</span>
        </div>
      ))}
    </div>
  );
};

const styles = {
  menuContainer: {
    position: 'absolute',
    bottom: '0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    //backgroundColor: '#ffffff',
    padding: '5px 0',
    //boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
  },
  icon: {
    width: 50,
    height: 50,
  },
  text: {
    fontSize: 12,
    marginTop: 5,
    color: '#333',
  },
};

export default BottomMenu;